import { configureStore } from '@reduxjs/toolkit'
import  tradingReducer  from './redux/reducer/reducer'
import imageReducer from './redux/image.reducer'
import addtocardReducer from './redux/addtocard.reducer'
export const store = configureStore({
  reducer: {
    Trading : tradingReducer,
    Images : imageReducer,
    CardItem : addtocardReducer
    }
})