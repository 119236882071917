import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Items: [],
    count: 0
}

const AddtocardSlice = createSlice({
    name: "CardItem",
    initialState,
    reducers: {
        addItems: (state, action) => {
            const existingItem = state.Items.find((item) => item.ItemId === action.payload.ItemId);
            if (!existingItem) {
                state.Items.push({ ...action.payload, quantity: 1 }); // Initialize quantity to 1
            } else {
                console.log("Item already in the cart");
            }
            state.count = state.Items.length; // Update count based on length of Items
        },
        increaseItem: (state, action) => {
            const item = state.Items.find((item) => item.ItemId === action.payload);
            if (item) {
                item.quantity += 1; // Increment quantity
            }
        },
        decreaseItem: (state, action) => {
            const item = state.Items.find((item) => item.ItemId === action.payload);
            if (item) {
                if (item.quantity > 1) {
                    item.quantity -= 1; // Decrement quantity, ensuring it doesn't go below 1
                } else {
                    state.Items = state.Items.filter((item) => item.ItemId !== action.payload); // Remove item if quantity is 0
                }
            }
            state.count = state.Items.length; // Update count based on length of Items
        },
        removeItem: (state, action) => {
            state.Items = state.Items.filter((item) => item.ItemId !== action.payload);
            state.count = state.Items.length; // Update the count
        },
    }
});

export const { addItems, increaseItem, decreaseItem,removeItem } = AddtocardSlice.actions;

export default AddtocardSlice.reducer;
