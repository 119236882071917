import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseItem, increaseItem, removeItem } from "../redux/addtocard.reducer";
import { Modal, Button } from "react-bootstrap";
import AddressDetails from "./address.details";

const CartItems = ({ show, handleClose }) => {
    const [showAddressModal, setShowAddressModal] = useState(false);
    const cartitem = useSelector((state) => state.CardItem.Items);
    const dispatch = useDispatch();

    const increase = (id) => {
        dispatch(increaseItem(id));
    };

    const decrease = (id) => {
        dispatch(decreaseItem(id));
    };

    const deleteItem = (id) => {
        dispatch(removeItem(id));
    };

    const handleProceedToCheckout = () => {

        setShowAddressModal(true);
        handleClose();
    };

    // Calculate the total price of all items in the cart
    const totalPrice = cartitem.reduce((acc, item) => acc + item.Price * item.quantity, 0);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                dialogClassName="modal-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Your Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-4">
                        {cartitem.length === 0 ? (
                            <div className="text-center py-4">
                                <p>Your cart is empty.</p>
                            </div>
                        ) : (
                            <>
                                <div className="row fw-bold border-bottom pb-1 ">
                                    <div className="col-4 col-md-2 text-center text-sm">View</div>
                                    <div className="col-8 col-md-2 text-center text-sm">Product Name</div>
                                    <div className="col-4 col-md-2 text-center text-sm">Quantity</div>
                                    <div className="col-8 col-md-2 text-center text-sm">Actions</div>
                                    <div className="col-4 col-md-2 text-center text-sm">Delete</div>
                                    <div className="col-8 col-md-2 text-center text-sm">Price</div>
                                </div>
                                {cartitem.map((item, index) => (
                                    <div key={index} className="row py-2 border-bottom align-items-center text-center">
                                        <div className="col-4 col-md-2">
                                            <img 
                                                src={item.ItemImg} 
                                                alt={item.ProdName} 
                                                className="img-fluid rounded" 
                                                style={{ maxWidth: '50px' }} 
                                            />
                                        </div>
                                        <div className="col-8 col-md-2 text-sm">{item.ProdName}</div>
                                        <div className="col-4 col-md-2 text-sm">{item.quantity}</div>
                                        <div className="col-8 col-md-2">
                                            <div className="btn-group me-2" role="group" aria-label="Quantity controls">
                                                <button 
                                                    className="btn btn-sm btn-outline-danger" 
                                                    onClick={() => decrease(item.ItemId)}
                                                    disabled={item.quantity === 1}
                                                >
                                                    -1
                                                </button>
                                                <button className="btn btn-sm btn-outline-secondary" disabled>
                                                    {item.quantity}
                                                </button>
                                                <button 
                                                    className="btn btn-sm btn-outline-success" 
                                                    onClick={() => increase(item.ItemId)}
                                                >
                                                    +1
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-2">
                                            <button 
                                                className="btn btn-sm btn-danger"
                                                onClick={() => deleteItem(item.ItemId)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                        <div className="col-8 col-md-2 text-sm">QAR {item.Price * item.quantity}</div>
                                    </div>
                                ))}
                                {/* Total Price Section */}
                                <div className="row pt-3">
                                    <div className="col-12 text-end">
                                        <h5>Total Price: QAR {totalPrice.toFixed(2)}</h5>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleProceedToCheckout}>
                        Proceed to Checkout
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* AddressDetails Modal */}
            <AddressDetails show={showAddressModal} handleClose={() => setShowAddressModal(false)} />
        </>
    );
};

export default CartItems;
