import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    image: [],
    loading: false,
    error: null,
};

const imageSlice = createSlice({
    name: 'Images',
    initialState,
    reducers: {
        loadImagesStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        loadImagesSuccess: (state, action) => {
            state.loading = false;
            state.image = action.payload; // Update state with fetched data
        },
        loadImagesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

// Export actions
export const { loadImagesStart, loadImagesSuccess, loadImagesFailure } = imageSlice.actions;

// Thunk function to handle async fetching
export const fetchImages = () => async (dispatch) => {
    dispatch(loadImagesStart()); // Start loading
    try {
        const response = await fetch('https://backend-rxl.vercel.app/api/get-skincare');
        if (!response.ok) {
            throw new Error("API not found");
        }
        const data = await response.json();
        dispatch(loadImagesSuccess(data)); // Dispatch success action with fetched data
    } catch (error) {
        dispatch(loadImagesFailure(error.message)); // Dispatch failure action with error
        console.error(error);
    }
};

// Export the reducer
export default imageSlice.reducer;
