import React, { useEffect } from "react";
import './mainpage.css';
import HeaderPage from "../Header/Headerpage";
import Footer from "../footer/footer";
import Category from "../category/category";
import SlidingImages from "../slidingImages";
import { useDispatch } from "react-redux";
// import { loadTheImages } from "../../redux/image.reducer";
import { fetchImages } from "../../redux/image.reducer";
import FontWhatsapp from "../whatsapp/font.whatsapp";
const MainPage = () => {
 
  const dispatch = useDispatch();
  useEffect(()=>{
     
      dispatch(fetchImages());
    
    
  },[dispatch])
  // Images https://images.pexels.com/photos/4465830/pexels-photo-4465830.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1
  // /assets/bodycareImage.jpg
  const categoryImage = [{Image:'https://images.pexels.com/photos/4465830/pexels-photo-4465830.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
           Pname: 'Skin Care Products',
           Description:'Skin nourishment'
  },
  {
    Image:'https://media.istockphoto.com/id/1320345717/photo/various-cosmetic-accessories-for-makeup-and-manicure-on-trendy-pastel-pink-background-with.webp?a=1&b=1&s=612x612&w=0&k=20&c=KrBhfBuh0ZPGzuqM5iW_urCfZolRzmi5yC94AcNgw28=',
    Pname:'MakeUp Products',
    Description:'Beauty enhancement'
  },
  {
    Image:'https://media.istockphoto.com/id/1645715726/photo/long-hair-care-routine.webp?a=1&b=1&s=612x612&w=0&k=20&c=_arR-lwm-HVu3NhJLHaoGi7pLIK8cUXuWghyR--UDT4=',
    Pname:"Hair Care Products",
    Description:'Hair maintenance'
  },
  {
    Image:'https://t3.ftcdn.net/jpg/02/44/68/22/240_F_244682210_znDgzRGqit1AK8STISOd2CEUKVJ4T3uJ.jpg',
    Pname:'Body care Products',
    Description:'Body hydration'
  }
  ,{
    Image:'https://media.istockphoto.com/id/1327437235/photo/set-of-perfume-bottles-with-fragrance-spaces-and-fruits.jpg?s=612x612&w=0&k=20&c=hD1-qv_QAJmF68GLmgXEvgtkjRAlcN5uQ0bGpT4UfvY=',
    Pname:'Fragrance Products',
    Description:'Scent indulgence'
  },
  {
    Image:'https://media.istockphoto.com/id/1020439322/photo/hairdresser-tools-on-black-background-with-copy-space-in-center.webp?a=1&b=1&s=612x612&w=0&k=20&c=8xHxk8ibTC_-n0txjeuF_m85e_rk2wG_nW4O5bF5yOw=',
    Pname:'Beauty Product Tools and Accessories',
    Description:'Application essentials'
  }
  ]
  
  return (
    <div className="app-container" >
      <HeaderPage />
      <SlidingImages/>
      <div className="main-content gap-3 mt-4">
        {categoryImage.map((item,index)=>{
          
          return <Category key={index} item={item}/>
        })}
        
        
      </div>
      <FontWhatsapp/>
      <Footer />
    </div>
  );
}

export default MainPage;
