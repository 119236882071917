import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const AddressDetails = ({ show, handleClose ,product}) => {
  const cartItems = useSelector((state) => state.CardItem.Items);

  const [address, setAddress] = useState({
    name: "",
    phone: "",
    addressLine1: "",
    postalCode: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submission and send to WhatsApp
  const handlePlaceOrder = (e) => {
    e.preventDefault();
    const products = `Product: ${product.ProductName}, Quantity: 1, Price: QAR ${product.Price}`;
    // Construct the product details with proper formatting
    const productDetails = cartItems.map(item => 
      `Product: ${item.ProdName}, Quantity: ${item.quantity}, Price: QAR ${item.Price * item.quantity}`
    ).join('\n'); // Use '\n' for new lines between products
  
    // Construct the address details with proper formatting
    const addressDetails = `
      Name: ${address.name}
      Phone: ${address.phone}
      Address: ${address.addressLine1}, ${address.postalCode || 'N/A'}
    `.trim(); // Use plain new lines
  
    // Combine product and address details into one message
    let message;
    if(productDetails){
      message = `Order Details:\n${productDetails}\n\nShipping Address:\n${addressDetails}`;
  
    }
    else{
      message = `Order Details:\n${products}\n\nShipping Address:\n${addressDetails}`;
  
    }
     
    // Construct the WhatsApp URL with the properly encoded message
    const whatsappUrl = `https://wa.me/97451822231?text=${encodeURIComponent(message)}`;
  
    // Open the WhatsApp chat with the pre-filled message
    window.open(whatsappUrl, "_blank");
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter Address Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handlePlaceOrder}>
          {/* Full Name */}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={address.name}
              onChange={handleChange}
              placeholder="Enter your full name"
              required
            />
          </Form.Group>
          {/* Mobile Number */}
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={address.phone}
              onChange={handleChange}
              placeholder="Enter your mobile number"
              required
            />
          </Form.Group>
          {/* Address Line 1 */}
          <Form.Group className="mb-3" controlId="formAddressLine1">
            <Form.Label>Full Address</Form.Label>
            <Form.Control
              type="text"
              name="addressLine1"
              value={address.addressLine1}
              onChange={handleChange}
              placeholder="Enter Full Address"
              required
            />
          </Form.Group>
          
          {/* Postal Code */}
          <Form.Group className="mb-3" controlId="formPostalCode">
            <Form.Label>Street & Building No.</Form.Label>
            <Form.Control
              type="text"
              name="postalCode"
              value={address.postalCode}
              onChange={handleChange}
              placeholder="Enter Street & Building No."
              required
            />
          </Form.Group>
          <p> <span className="text-success">Payment Method:</span> <span className="info-text-emphasis">Cash on Delivery only</span></p>
          <Button variant="primary" type="submit" className="w-100">
            Place Order via WhatsApp
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddressDetails;
