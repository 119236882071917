import React ,{useState,useEffect}from 'react';
import { Carousel } from 'react-bootstrap';

const SlidingImages = () => {
    const [carouselHeight, setCarouselHeight] = useState('70vh');

    const updateHeight = () => {
        if (window.innerWidth <= 576) {
            setCarouselHeight('30vh');
        } else {
            setCarouselHeight('70vh');
        }
    };

    useEffect(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const images = [
        
        {
            
            src: 'https://static.vecteezy.com/system/resources/thumbnails/040/835/773/small/ai-generated-collection-of-make-up-and-cosmetic-beauty-products-arranged-on-blue-background-each-one-is-shot-separately-photo.jpg',
            text: 'Make-up Collection ',
        },
        {
            src: 'https://static.vecteezy.com/system/resources/thumbnails/046/664/203/small/beauty-industry-cosmetics-makeup-flat-lay-of-various-cosmetic-powders-and-blushes-with-soft-makeup-brush-powders-are-scattered-on-a-white-background-top-view-photo.jpg',
            text: 'Cosmetic Powders and Blushes',
        },
        {
            src: 'https://static.vecteezy.com/system/resources/thumbnails/023/999/625/small/makeup-cosmetics-and-products-mockup-pink-background-generative-ai-photo.jpg',
            text: 'Cosmetics and Products ',
        },
        {
            src: 'https://img.freepik.com/free-vector/cosmetic-elements-realistic-style_23-2147562679.jpg?ga=GA1.1.367444534.1725135589&semt=ais_hybrid',
            text: 'Realistic Cosmetic Elements',
        },
        {
            src: 'https://img.freepik.com/premium-vector/cosmetic-package-template-design_382352-11060.jpg?ga=GA1.1.367444534.1725135589&semt=ais_hybrid',
            text: 'Cosmetic Package Template',
        },
        {
            src: 'https://img.freepik.com/free-vector/realistic-natural-cosmetic-advertisement_52683-8152.jpg?ga=GA1.1.367444534.1725135589&semt=ais_hybrid',
            text: 'Natural Cosmetic Advertisement',
        },
    ];

    return (
        <Carousel indicators={false} controls={true} interval={3000} >
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100  "
                        src={image.src}
                        style={{ height: carouselHeight , objectFit: 'cover' }} // Added margin here
                        alt={`Slide ${index + 1}`}
                    />
                    <Carousel.Caption>
                        <h2 className='opacity-75 fs-1 text-secondary-emphasis'>{image.text}</h2>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SlidingImages;


