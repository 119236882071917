import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import './MenuButton.css';
import { Link } from 'react-router-dom';
const MenuButton = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div className="menu-container">
      <button className="menu-button" onClick={toggleMenu}>
        <FaBars />
      </button>
      {open && (
        <ul className="menu-list">
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/skincare'>SKINCARE</Link></li>
          <li><Link to='/skincare'>MAKEUP</Link></li>
          <li><Link to='/skincare'>HAIRCARE</Link></li>
          <li><Link to='/skincare'>BODYCARE</Link></li>
          <li><Link to='/skincare'>FRAGRANCE</Link></li>
          <li><Link to='/skincare'>TOOLS & ACCESSORIES</Link></li>
        </ul>
      )}
    </div>
  );
};

export default MenuButton;
