import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value : 0,
    show : true
}
export const tradingSlice = createSlice({
    name:'Trading',
    initialState,
    reducers:{
        increament:(state)=>{
            state.value += 1
        },
        decrement:(state,action)=>{
            state.value -= 1
        },
        toggle:(state,action)=>{
            state.show  = !state.show    
        }
    }
})
export const {increament,decrement,toggle} = tradingSlice.actions;
export default tradingSlice.reducer;