import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggle } from "../../redux/reducer/reducer";
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
// import './category.css'; // Optional: Keep if you have additional styles

const Category = (props) => {
    const dispatch = useDispatch();
    const showprod = useSelector((state)=>state.Trading.show);
    const navigate = useNavigate();
    const { item,key } = props;
    const openSubPages = () => {
        if(!showprod){
            dispatch(toggle());
        }
        navigate('/skincare');
        
    }

    return (
        <div className="col-8 col-sm-4 col-md-2 col-lg-2 mb-4" key={key}>
            <div 
                className="card h-100 bg-secondary-subtle text-black" 
                onClick={openSubPages} 
                style={{ cursor: 'pointer' }}
            >
                <img 
                    src= {item.Image}
                    className="card-img-top img-fluid" 
                    alt={item.ImageName} 
                    style={{ objectFit: 'cover', height: '200px' }} // Adjust height as needed
                />
                <div className="card-body rounded-bottom bg-body-secondary text-center">
                    <h5 className="card-title">{item.Pname}</h5>
                    <p className="card-text">{item.Description}</p>
                </div>
            </div>
        </div>
    );
}

export default Category;
