import React from 'react';
import './footer.css'
import { FaFacebook, FaInstagram, FaWhatsapp, FaLinkedin, FaTwitter, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
// import WhatsAppLink from '../whatsapp/whatsapp';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-about">
                    <h2>About Our Company</h2>
                    <p>Our company is committed to providing the best services and products to our customers. We believe in quality, innovation, and customer satisfaction.</p>
                </div>
                <div className="footer-contact">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><FaMapMarkerAlt /> Najma B Ring road, Doha Qatar</li>
                        <li><FaPhone />55205640, 51822231</li>
                        <li><FaEnvelope /> theraxaultrading1807@gmail.com</li>
                    </ul>
                </div>
                <div className="footer-social">
                    <h2>Follow Us...</h2>
                    <ul>
                        <li><a href="https://www.facebook.com/profile.php?id=61563006175949" target="_blank" rel="noopener noreferrer"><FaFacebook /> Facebook</a></li>
                        <li><a href="https://www.instagram.com/qatarbeautybliss?utm_source=qr&igsh=MTRtNTVpMGdvZnE5OA==" target="_blank" rel="noopener noreferrer"><FaInstagram /> Instagram</a></li>
                        <li><a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer"><FaWhatsapp />Whatsapp</a></li>
                        <li><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /> LinkedIn</a></li>
                        <li><a href="https://x.com/RaxaulTrading?t=KgA8NvTAJklw4_VCyLRNLw&s=09" target="_blank" rel="noopener noreferrer"><FaTwitter /> Twitter</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
