import React from "react";

import {createBrowserRouter,RouterProvider} from 'react-router-dom';
import MainPage from "./component/mainPage/mainpage";
import Skincare from "./component/skincareProd/skincare";
// import SkincareDetail from "./component/ShowProduct/showProduct";
import ProductDetails from "./component/productDetail";
const App = ()=>{
  const router = createBrowserRouter([
    {
      path:'/',
      element:<MainPage/>
    },
    {
      path: '/skincare',
      element: <Skincare />, // The parent route that renders `Skincare`
      children: [
        {
          path: 'subskincare/:id/:_id', // This is a child route relative to `/sub-category`
          element: <ProductDetails/>
        }
      ]

    }
  ]);
  return(

    <RouterProvider router={router} />

  )
}
export default App;