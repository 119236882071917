import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from '../redux/reducer/reducer';
import { Carousel } from 'react-bootstrap';
import { addItems } from '../redux/addtocard.reducer';
import AddressDetails from './address.details';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, _id } = useParams();
  const productArray = useSelector((state) => state.Images.image);
  const loading = useSelector((state) => state.Images.loading);
  const show = useSelector((state) => state.Trading.show);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [successMessage, setSuccessMessage] = useState(null); 
  const [showAddressModal, setShowAddressModal] = useState(false); // State to show/hide address modal

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleGoBack = () => {
    if (!show) {
      dispatch(toggle());
    }
    navigate(-1); 
  };

  if (loading) {
    return (
      <div className="container my-5 text-center">
        <p>Loading product details...</p>
      </div>
    );
  }

  if (!productArray || !productArray.listOfImg || !productArray.Skinproduct) {
    return (
      <div className="container my-5 text-center">
        <p>No product data available at the moment. Please try again later.</p>
      </div>
    );
  }

  const imageurl = productArray.listOfImg?.find((item) => item.id === id);
  const product = productArray.Skinproduct?.find((item) => item._id === _id);
  
  if (!product || !imageurl) {
    return (
      <div className="container my-5 text-center">
        <p>Product not found. Please check the product ID or try again later.</p>
        <button 
          className="btn btn-secondary mt-3"
          onClick={handleGoBack}
        >
          Go Back
        </button>
      </div>
    );
  }

  const imageUrls = Object.values(imageurl).filter((item, index, arr) => {
    return index !== arr.length - 1;
  });

  const addintocard = (PName, PPrice) => {
    const item = {
      ItemId: product._id,
      ItemImg: imageUrls[0],
      ProdName: PName,
      Price: PPrice
    }
    dispatch(addItems(item));
    setSuccessMessage("Item successfully added to the cart!"); 
    setTimeout(() => setSuccessMessage(null), 3000);
  };

  const handlePlaceOrder = () => {
    setShowAddressModal(true); // Show the address modal when place order is clicked
  };

  const handleCloseModal = () => {
    setShowAddressModal(false); // Close the address modal
  };

  return (
    <>
      <div className="container my-5">
        {successMessage && (
          <div className='d-flex justify-content-center'>
            <div className="alert alert-success mt-3 w-100 w-md-50 w-lg-25 text-center" role="alert">
              {successMessage}
            </div>
          </div>
        )}
        {!isMobile && (
          <button
            className="btn btn-secondary mb-3"
            onClick={handleGoBack}
          >
            ← Back
          </button>
        )}
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <Carousel indicators={false} controls={true} interval={null} wrap={true}>
              {imageUrls.map((imgUrl, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 rounded-4"
                    src={imgUrl}
                    style={{ height: '300px', objectFit: 'cover' }}
                    alt={`Slide ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          <div className="col-md-6">
            <h2>{product.ProductName}</h2>
            <p>
              Rating:
              <span className="badge bg-warning text-dark ms-2">
                4.5 ⭐
              </span>
            </p>
            <h4 className="text-success">Price: QAR {product.Price}</h4>

            <div className="row mt-4">
              <div className="col-6">
                <button className="btn btn-primary w-100" onClick={() => addintocard(product.ProductName, product.Price)}>Add to Cart</button>
              </div>
              <div className="col-6">
                <button className="btn btn-success w-100" onClick={handlePlaceOrder}>Place Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Address Details Modal */}
      <AddressDetails show={showAddressModal} handleClose={handleCloseModal} product={product} />
    </>
  );
};

export default ProductDetails;
