import React, { useState, useEffect } from "react";
import HeaderPage from "../Header/Headerpage";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import { Outlet } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { toggle } from "../../redux/reducer/reducer";
import { fetchImages } from "../../redux/image.reducer";
const Skincare = () => {
    const dispatch = useDispatch();
    const showprod = useSelector((state)=>state.Trading.show);
    const imgearray = useSelector((state)=>state.Images.image);
    const loading = useSelector((state)=>state.Images.loading);
    const navigate = useNavigate();
    // const location = useLocation();
    const [cardWidth, setCardWidth] = useState('16rem');
    const [cardHeight, setCardHeight] = useState('150px');
    // const [showdetails, setShow] = useState(true);
    useEffect(()=>{
            dispatch(fetchImages());
    },[dispatch])
    useEffect(() => {
        const handleResize = () => {
            
            if (window.innerWidth <= 576) {
                setCardWidth('8rem');
                setCardHeight('100px');
            } else {
                setCardWidth('16rem');
                setCardHeight('150px');
            }
        };

        // Set initial card dimensions
        handleResize();

        // Update card dimensions on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
   
    useEffect(() => {
        window.scrollTo(0, 0);
        const handlePopState = () => {
            if (!showprod) {
                dispatch(toggle());  // Ensure products are shown when navigating back
            }
        };

        const handleKeyDown = (event) => {
            // Detect Alt+Right Arrow (Forward Navigation)
            if (event.altKey && event.key === 'ArrowRight') {
                navigate(1);   // Go forward in history
            }
        };

        window.addEventListener('popstate', handlePopState);
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup listeners on unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [dispatch, navigate, showprod]);
    const opendetailedpage = (id,_id) => {
        // console.log("show product status",showprod)
        if(showprod){
            dispatch(toggle());
        }
        
        navigate(`subskincare/${id}/${_id}`);
        
        // console.log("show product status",showprod)
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <HeaderPage />
            {showprod && <div className="container">
               
                <div className="row">
                    {imgearray.listOfImg && imgearray.Skinproduct && imgearray.listOfImg.length > 0 ? (
                        imgearray.listOfImg.map((item, index) => (
                            <div
                                key={index}
                                className="col-6 col-sm-4 col-md-3 mt-3 col-lg-2 mb-3 d-flex justify-content-center"
                                onClick={() => opendetailedpage(item.id,imgearray.Skinproduct[index]._id)}
                                
                            >
                                <div className="card h-100 bg-secondary-subtle text-black" style={{ width: cardWidth,cursor:'pointer' }}>
                                    <img
                                        src={item["0"]} // Ensure this is the correct way to access the image
                                        alt="img"
                                        className="card-img-top img-fluid"
                                        style={{ objectFit: 'cover', height: cardHeight }}
                                    />
                                    <div className="card-body rounded-bottom bg-body-secondary text-center">
                                        <p className="card-title" style={{fontSize:'0.8rem',fontWeight:500}}>{imgearray.Skinproduct[index]?.ProductName || 'Product Name Unavailable'}</p>
                                        <h5 className="card-title" style={{fontWeight:500,fontSize:'1rem'}}>Price: QAR {imgearray.Skinproduct[index].Price}</h5>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No skincare products </div>

                        
                    )}
                </div>
                
            </div>}
            <Outlet />
            <Footer /> 
        </>
    );
};

export default Skincare;


