import React, { useState } from 'react';
import logo from './favicon.ico';
import MenuButton from '../MenuButton/MenuButton';
// import WhatsAppLink from '../whatsapp/whatsapp';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesome
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import CartItems from '../cartItems';
const HeaderPage = () => {
    
    const count = useSelector((state)=>state.CardItem.count);
    // const [showCart, setShowCart] = useState(false); // State to manage visibility
    const [showCart, setShowCart] = useState(false);

    const handleShowCart = () => setShowCart(true);
    const handleCloseCart = () => setShowCart(false);
    const navigate = useNavigate();
    const gotohomepage = ()=>{
        navigate('/')
    }
    
    return (
        <header className="container-fluid bg-light py-2 sticky-top">
            <div className="row align-items-center justify-content-between">
                <div className="col-3 col-md-1 d-flex align-items-center" onClick={gotohomepage}>
                    <img src={logo} alt="Logo" className="img-fluid" style={{ maxHeight: '50px',cursor:'pointer' }} />
                </div>
                <div className="col-md-3   text-center d-none  d-md-block">
                    <h1 className="h3 m-0">The Raxaul Global Trading</h1>
                </div>
                
                <div className="col-6 col-md-3 d-flex justify-content-end m-0 align-items-center">
                    {/* <WhatsAppLink /> */}
                    <div className="position-relative m-3 " onClick={handleShowCart} style={{cursor:'pointer'}}>
                        <FontAwesomeIcon icon={faCartShopping} className="fs-4" />
                        <span
                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning text-white"
                            style={{ fontSize: '0.8rem', width: '20px', height: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '1' }}
                        >
                            {count}
                        </span>
                    </div>
                     {/* Conditionally render CartItems component */}
                    <MenuButton className="d-md-none" />
                </div>
                
                
            </div>
            <CartItems show={showCart} handleClose={handleCloseCart} />
        </header>
    );
};

export default HeaderPage;

